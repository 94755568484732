import { getImage, ImageQuery, ValueProps } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React from 'react';
import { step2Ideas as ideas } from '../../../data/content/how-it-works';
import { metadata } from '../../../data/metadata/images';
import { DottedImage } from '../../dotted-image';
import { Highlight, ResponsivePrimaryText, ResponsiveSectionHeader } from '../../typography';
import { ValuePropsWrapper } from '../../wrappers';

const SectionStyle = createOwnUpStyle({
  padding: '30px 64px 0px',
  variants: {
    mediumAndDown: {
      padding: 10
    }
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

const FirstStyle = createOwnUpStyle({
  padding: '30px 0',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  variants: {
    mediumAndDown: {
      display: 'block',
      padding: 30
    }
  }
});

const First = createOwnUpComponent(OwnUpBox, FirstStyle);

const ContentWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingBottom: 30,
  marginRight: 30,
  variants: {
    large: {
      width: 'calc(50% - 30px)'
    },
    mediumAndDown: {
      marginRight: 0,
      marginLeft: 0
    }
  }
});

const ContentWrapper = createOwnUpComponent(OwnUpBox, ContentWrapperStyle);

const ImageWrapperStyle = createOwnUpStyle({
  width: '50%',
  '& img': {
    borderRadius: '50%'
  },
  variants: {
    mediumAndDown: {
      paddingBottom: 30,
      width: '100%'
    }
  }
});

const ImageWrapper = createOwnUpComponent(OwnUpBox, ImageWrapperStyle);

export const imageStyle = {
  maxWidth: 390,
  margin: 'auto',
  position: 'relative' as const,
  borderRadius: '50%'
};

export const KatinaImage = ({ imageData }: { imageData: ImageQuery }) =>
  getImage(imageData, 'katina.jpg', metadata, imageStyle);

export const Step2 = ({ Image }: { Image: JSX.Element | null }) => {
  return (
    <Section aria-label="step 2">
      <First>
        <ContentWrapper>
          <ResponsiveSectionHeader style={{ margin: '0 0 22px' }}>
            <Highlight>Step 2:</Highlight>
            <br />
            Meet your
            <br />
            home advisor.
          </ResponsiveSectionHeader>
          <ResponsivePrimaryText>
            Buying a home is likely the biggest financial transaction of your life. It can be
            stressful, so you get a dedicated expert home advisor who provides you ongoing support
            every step of the way.
          </ResponsivePrimaryText>
        </ContentWrapper>
        <ImageWrapper>
          <DottedImage Image={Image} />
        </ImageWrapper>
      </First>
      <ValuePropsWrapper>
        <ValueProps valueProps={ideas} metadata={metadata} />
      </ValuePropsWrapper>
    </Section>
  );
};
